import React from "react";
import "leaflet/dist/leaflet.css";
import Section from "../components/common/Section";
import Layout from "../layouts";
import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import Loadable from "@loadable/component";

const MapView = Loadable(() => import("../components/Map/Map"));

const Map = () => {
  const { t } = useI18next();
  return (
    <Layout title="general.map">
      <div className="mt-24">
        <MapView />
      </div>
    </Layout>
  );
};

export default Map;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
